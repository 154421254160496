import Popper from 'popper.js';

const DEFAULT_OPTIONS = {
    container: false,
    delay: 0,
    html: false,
    placement: 'top',
    content: '',
    template: '<div class="tooltip" role="tooltip"><div x-arrow class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    trigger: 'hover focus'
};

export class TooltipPopper {

    private reference: Element;
    private options: any;
    private isOpen: boolean;
    private innerSelector: any;
    private arrowSelector: any;
    private events: Array<any>;
    private tooltipNode: any;
    private popperInstance: any;
    private showTimeout: any;
    private updateTimeout: any;
    private id: string;

    private static findContainer(container: any, reference: any) {
        // if container is a query, get the relative element
        if (typeof container === 'string') {
            container = window.document.querySelector(container);
        } else if (container === false) {
            container = reference.parentNode;
        }
        return container;
    }

    /**
     * Create a new TooltipPopper instance
     * @param reference - The reference element used to position the tooltip
     * @param options
     * @param dontHideOnChange
     * @param options.placement=bottom
     *      Placement of the popper accepted values: `top(-start, -end), right(-start, -end), bottom(-start, -end),
     *      left(-start, -end)`
     *
     * @param options.container=false - Append the tooltip to a specific element.
     * @param options.delay=0
     *      Delay showing and hiding the tooltip (ms) - does not apply to manual trigger type.
     *      If a number is supplied, delay is applied to both hide/show.
     *      Object structure is: `{ show: 500, hide: 100 }`
     * @param options.html=false - Insert HTML into the tooltip. If false, the content will inserted with `innerText`.
     * @param options.placement='top' - One of the allowed placements, or a function returning one of them.
     * @param options.template='<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
     *      Base HTML to used when creating the tooltip.
     *      The tooltip's `content` will be injected into the `.tooltip-inner` or `.tooltip__inner`.
     *      `.tooltip-arrow` or `.tooltip__arrow` will become the tooltip's arrow.
     *      The outermost wrapper element should have the `.tooltip` class.
     * @param options.content=''
     * @param options.trigger='hover focus'
     *      How tooltip is triggered - click | hover | focus | manual.
     *      You may pass multiple triggers; separate them with a space. `manual` cannot be combined with any other trigger.
     * @param options.boundariesElement
     *      The element used as boundaries for the tooltip. For more information refer to Popper.js'
     *      [boundariesElement docs](https://popper.js.org/popper-documentation.html)
     * @param options.offset=0 - Offset of the tooltip relative to its reference. For more information refer to Popper.js'
     *      [offset docs](https://popper.js.org/popper-documentation.html)
     * @return instance - The generated tooltip instance
     */
    constructor(reference: any, options: any, private dontHideOnChange: boolean = false) {
        this.id = Math.random().toString(36).substr(2, 10);
        this.arrowSelector = '.tooltip-arrow, .tooltip__arrow';
        this.innerSelector = '.tooltip-inner, .tooltip__inner';
        this.events = [];

        // apply user options over default ones
        options = { ...DEFAULT_OPTIONS, ...options };

        // cache reference and options
        this.reference = reference;
        this.options = options;

        // get events list
        const events = typeof options.trigger === 'string' ? options.trigger.split(' ').filter((trigger: any) => {
                return ['click', 'hover', 'focus'].indexOf(trigger) !== -1;
            }) : [];

        // set initial state
        this.isOpen = false;

        // set event listeners
        this.setEventListeners(reference, events, options);
    }

    show() {
        this.clearShowTimeout();
        // don't show if it's already visible
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;

        // if the tooltipNode already exists, just show it
        if (this.tooltipNode) {
            this.tooltipNode.classList.remove('hidden');
            this.tooltipNode.setAttribute('aria-hidden', 'false');
            this.popperInstance.update();
            return;
        }

        const content = this.options.content;

        // don't show tooltip if no content is defined
        if (!content) {
            return;
        }

        // create tooltip node
        const tooltipNode = this.create(this.reference, this.options.template, content, this.options.html);

        // Add `aria-describedby` to our reference element for accessibility reasons
        tooltipNode.setAttribute('aria-describedby', tooltipNode.id);
        // hide element before popper.js is initialized
        tooltipNode.style.opacity = 0;

        // append tooltip to container
        const container = TooltipPopper.findContainer(this.options.container, this.reference);
        if (container) {
            container.appendChild(tooltipNode);

            const placementPriorities = ['left', 'right', 'top', 'bottom'];
            let modifiers: any = {
                preventOverflow: {
                    escapeWithReference: this.options.escapeWithReference,
                    priority: Array.isArray(this.options.placement) ? this.options.placement : placementPriorities.sort(p => p === this.options.placement ? -1 : 1)
                },
                arrow: {
                    element: this.arrowSelector
                }
            };
            if (this.options.offset) {
                modifiers.offset = {
                    offset: this.options.offset
                };
            }

            if (this.options.modifiers) {
                modifiers = {...modifiers, ...this.options.modifiers};
            }

            const popperOptions: Popper.PopperOptions = {
                placement: this.options.placement,  // placement is set is preventOverflow priority
                removeOnDestroy: true,
                modifiers,
                onCreate: this.OnPopperCreated.bind(this)
            };

            this.popperInstance = new Popper(this.reference, tooltipNode, popperOptions);

            this.tooltipNode = tooltipNode;
            this.tooltipNode.classList.remove('hidden');
        }

    }

    hide() {
        this.clearShowTimeout();
        this.clearUpdateTimeout();

        // don't hide if it's already hidden
        if (!this.isOpen) {
            return;
        }

        this.isOpen = false;

        if (this.tooltipNode) {
            // hide tooltipNode
            this.tooltipNode.classList.add('hidden');
            this.tooltipNode.setAttribute('aria-hidden', 'true');
        }
    }

    toggle() {
        if (this.isOpen) {
            this.hide();
        } else {
            this.show();
        }
    }

    update() {
        if (this.popperInstance) {
            if (this.options.placement === 'auto') {
                // placement must be set to 'auto' to invalidate current placement after resize
                this.popperInstance.options.placement = 'auto';
            }
            this.popperInstance.update();
        }
    }

    updateContent(content: string) {
        this.options.content = content;
        if (this.tooltipNode) {
            const contentNode = this.tooltipNode.querySelector(this.innerSelector);
            if (contentNode) {
                if (this.dontHideOnChange) {
                    contentNode.innerHTML = content;
                    this.update();
                    return;
                }
                const isOpen = this.isOpen;
                this.hide();
                this.clearUpdateTimeout();
                this.updateTimeout = setTimeout(() => {
                    contentNode.innerHTML = content;
                    if (isOpen) {
                        this.show();
                    }
                    this.update();
                }, 0);
            }
        }
        this.update();
    }

    dispose() {
        this.hide();

        if (this.popperInstance) {
            // destroy instance
            this.popperInstance.destroy();
        }

        // remove event listeners
        this.events.forEach(({func, event}) => {
            this.reference.removeEventListener(event, func);
        });
        this.events = [];


        if (this.tooltipNode) {
            // destroy tooltipNode
            const parentNode = this.tooltipNode.parentNode;
            if (parentNode) {
                parentNode.removeChild(this.tooltipNode);
            }
            this.tooltipNode = null;
        }
    }

    /**
     * Creates a new tooltip node
     * @memberof Tooltip
     * @param reference
     * @param template
     * @param content
     * @param allowHtml
     * @return tooltipNode
     */
    private create(reference: any, template: any, content: any, allowHtml: any) {
        if (content instanceof Element) {
            return content;
        }
        // create tooltip element
        const tooltipGenerator = window.document.createElement('div');
        tooltipGenerator.innerHTML = template;
        const tooltipNode: any = tooltipGenerator.childNodes[0];

        // add unique ID to our tooltip (needed for accessibility reasons)
        tooltipNode.id = `tooltip_${this.id}`;

        // set initial `aria-hidden` state to `false` (it's visible!)
        tooltipNode.setAttribute('aria-hidden', 'false');

        // add content to tooltip
        const contentNode = tooltipGenerator.querySelector(this.innerSelector);
        if (content.nodeType === 1) {
            // if content is a node, append it only if allowHtml is true
            if (allowHtml) {
                contentNode.appendChild(content);
            }
        } else if (content instanceof Function) {
            // if content is a function, call it and set innerText or innerHtml depending by `allowHtml` value
            const contentText = content.call(reference);
            allowHtml ? contentNode.innerHTML = contentText : contentNode.innerText = contentText;
        } else {
            // if it's just a simple text, set innerText or innerHtml depending by `allowHtml` value
            allowHtml ? contentNode.innerHTML = content : contentNode.innerText = content;
        }

        // return the generated tooltip node
        return tooltipNode;
    }

    private setEventListeners(reference: any, events: any, options: any) {
        const directEvents = events.map((event: any) => {
            switch (event) {
                case 'hover':
                    return 'mouseenter';
                case 'focus':
                    return 'focus';
                case 'click':
                    return 'click';
                default:
                    return;
            }
        });

        const oppositeEvents = events.map((event: any) => {
            switch (event) {
                case 'hover':
                    return 'mouseleave';
                case 'focus':
                    return 'blur';
                case 'click':
                    return 'click';
                default:
                    return;
            }
        }).filter((event: any) => !!event);

        if (this.options.trigger === 'hover' && !this.dontHideOnChange && oppositeEvents.indexOf('click') === -1) {
            // hide hover tooltip by click
            oppositeEvents.push('click');
        }


        // schedule show tooltip
        directEvents.forEach((event: any) => {
            const func = (evt: any) => {
                if (this.isOpen === true) {
                    return;
                }
                evt.usedByTooltip = true;
                this.scheduleShow(options.delay);
            };
            this.events.push({event, func});
            reference.addEventListener(event, func);
        });

        // schedule hide tooltip
        oppositeEvents.forEach((event: any) => {
            const func = (evt: any) => {
                if (evt.usedByTooltip === true) {
                    return;
                }
                this.scheduleHide(options.delay, evt);
            };
            this.events.push({event, func});
            reference.addEventListener(event, func);
        });
    }

    private clearShowTimeout() {
        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }
    }

    private clearUpdateTimeout() {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = null;
        }
    }

    private scheduleShow(delay: any) {
        // defaults to 0
        const computedDelay = delay && delay.show || delay || 0;
        this.clearShowTimeout();
        this.showTimeout = setTimeout(() => this.show(), computedDelay);
    }

    private scheduleHide(delay: any, evt: any) {
        this.clearShowTimeout();
        // defaults to 0
        const computedDelay = delay && delay.hide || delay || 0;
        setTimeout(() => {
            if (this.isOpen === false) {
                return;
            }
            if (!document.body.contains(this.tooltipNode)) {
                return;
            }

            // if we are hiding because of a mouseleave, we must check that the new
            // reference isn't the tooltip, because in this case we don't want to hide it
            if (evt.type === 'mouseleave') {
                const isSet = this.setTooltipNodeEvent(evt);

                // if we set the new event, don't hide the tooltip yet
                // the new event will take care to hide it if necessary
                if (isSet) {
                    return;
                }
            }

            this.hide();
        }, computedDelay);
    }

    private setTooltipNodeEvent(evt: any) {
        const relatedReference = evt.toElement;

        const callback = (event: any) => {
            const relatedReferenceLeave = event.toElement;

            // Remove event listener after call
            this.tooltipNode.removeEventListener(event.type, callback);

            // If the new reference is not the reference element
            if (!this.reference.contains(relatedReferenceLeave)) {

                // Schedule to hide tooltip
                this.scheduleHide(this.options.delay, event);
            }
        };

        if (this.tooltipNode.contains(relatedReference)) {
            // listen to 'mouseleave' on the tooltip element to be able to hide the tooltip
            this.tooltipNode.addEventListener(evt.type, callback);
            return true;
        }

        return false;
    }

    private OnPopperCreated() {
        // show tooltip
        this.tooltipNode.style.opacity = 1;
        this.popperInstance.scheduleUpdate(); // update popper position after show (sometimes initial pos is not correct)
    }
}
