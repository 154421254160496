import { NgModule } from '@angular/core';
import { ToolTipDirective, TooltipTemplateComponent, TooltipTemplatePlainComponent } from './toolTipDirective';

@NgModule({
    declarations: [
        ToolTipDirective,
        TooltipTemplateComponent,
        TooltipTemplatePlainComponent
    ],
    exports: [
        ToolTipDirective,
        TooltipTemplateComponent,
        TooltipTemplatePlainComponent
    ]
})
export class TooltipModule {}
